<template>
  <div class="promotersCard card" v-if="subscriber">
    <div class="promotersCard__detail">
      <ul class="promotersCard__detail--user">
        <router-link
          :to="`/${currentLang}/brand/influencer/${subscriber.id_user}/${friendURL(
            subscriber.name
          )}`"
          class="abs-link"
        ></router-link>
        <li class="promotersCard__detail--image">
          <img
            :src="`${$config.IMG_HOST}/50x50/${subscriber.avatar}`"
            alt=""
            class="img-fluid"
          />
        </li>
        <li>
          {{ subscriber.name }}
          <span>{{ subscriber.followers }} Followers</span>
        </li>
      </ul>
      <!--Gallery Start-->
      <CoolLightBox
        :items="subscriber.csImages"
        :index="index"
        @close="index = null"
      >
      </CoolLightBox>
      <div class="images-wrapper">
        <div
          class="image"
          v-for="(csImage, id) in subscriber.csImages"
          :key="id"
          @click="index = id"
        >
          <img v-lazy="`${csImage.src}`" />
        </div>
      </div>
      <!--Gallery End-->
      <span class="promotersCard__detail--price" v-if="subscriber.price">
        {{ formatPrice(subscriber.price) }}
      </span>
    </div>
    <div
      class="promotersCard__actions"
      v-if="subscriber.state == 'revision' || subscriber.state == 'pending'"
    >
      <div class="inline-btns">
        <a
          href="javascript:void(0);"
          class="btn btn-secondary"
          @click="$emit('decline', subscriber.id)"
          >Decline</a
        >
        <a
          href="javascript:void(0);"
          class="btn btn-green"
          @click="$emit('accept', subscriber.id)"
          >Accept</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { config } from "@/config.js";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import { Trans } from "@/plugins/Translation";

export default {
  props: {
    subscriber: [Object, Array],
  },
  components: {
    CoolLightBox,
  },
  data: function () {
    return {
      items: [],
      index: null,
      currentLang: Trans.currentLanguage,
    };
  },
  created() {
    for (let i = 0; i < this.subscriber.csImages.length; i++) {
      this.subscriber.csImages[i]["src"] =
        config.IMG_HOST + /350x450/ + this.subscriber.csImages[i]["srcs"];
    }
  },
};
</script>

<style lang="scss" scoped>
.promotersCard {
  position: relative;
  padding: rem(14px) rem(22px);
  margin-bottom: rem(16px);
  @media screen and (max-width: 991px) {
    padding-right: rem(30px);
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 10px !important;
  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }
  &__detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &--user {
      position: relative;
      display: flex;
      align-items: center;
      list-style: none;
      li {
        font-size: rem(18px);
        font-weight: 600;
        color: var(--textPrimary);
        span {
          display: block;
          color: var(--textSecondary);
          font-size: rem(14px);
          font-weight: 700;
          margin-top: 6px;
        }
      }
    }
    &--image {
      position: relative;
      width: 50px;
      min-width: 50px;
      height: 50px;
      background: #d8b4fa;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: rem(15px);
      overflow: hidden;
      // img {
      // 	height: 100%;
      // 	width: 100%;
      // }
      .status {
        position: absolute;
        width: 14px;
        height: 14px;
        background: #24d694;
        border-radius: 50%;
        right: 0;
        bottom: 0;
      }
    }
    &--price {
      font-size: rem(14px);
      color: var(--primary);
      padding: 0 rem(12px);
      font-weight: 700;
      font-family: $secondary-font;
    }
  }
  &__actions {
    position: relative;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    padding-left: rem(13px);
    @media screen and (min-width: 576px) {
      &::before {
        content: "";
        position: absolute;
        height: 60px;
        top: -10px;
        left: 0;
        border: 1px solid #c9ced6;
        border-radius: 50px;
        overflow: hidden;
        @media screen and (max-width: 991px) {
          top: -13px;
        }
      }
    }
    /*@media screen and (max-width: 575px) {
      margin-top: rem(20px);
    }*/
    .inline-btns {
      display: flex;
      .btn-secondary {
        margin-right: 6px;
      }
    }
  }

  .images-wrapper {
    cursor: pointer;
    display: flex;
    margin-right: auto;
    margin-left: 10px;
    .image {
      flex: 60px;
      max-width: 60px;
      margin-right: 10px;
      border-radius: 5px;
      overflow: hidden;
      img {
        max-width: 100%;
      }
    }
  }
}
</style>
